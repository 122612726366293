<template>
  <v-row>
    <v-col offset="3" cols="6">
      <v-card>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-select
              :items="examinations"
              v-model="form.examinationId"
              item-text="name"
              item-value="id"
              label="Kíp thi"
              :rules="[v => !!v || 'Bạn chưa nhập trường này']"
              @change="
                getSubjects($event);
                resetOriginalExams();
              "
            ></v-select>
            <v-select
              :items="subjects"
              item-text="fullName"
              v-model="form.subjectId"
              item-value="id"
              label="Học phần"
              :rules="[v => !!v || 'Bạn chưa nhập trường này']"
            ></v-select>
            <v-select
              :items="baseSubjects"
              item-text="fullName"
              item-value="id"
              v-model="form.baseSubjectId"
              label="Từ học phần"
              @change="resetOriginalExams()"
              :rules="[v => !!v || 'Bạn chưa nhập trường này']"
            ></v-select>
            <v-select
              :items="originalExamsFiltered"
              item-text="name"
              item-value="id"
              v-model="form.originalExamIds"
              multiple
              label="Đề gốc"
              :rules="[v => v.length > 0 || 'Bạn chưa chọn trường này']"
            ></v-select>
            <v-text-field
              type="number"
              v-model="form.quantity"
              label="Số lượng đề"
              :rules="[v => !!v || 'Bạn chưa nhập trường này']"
            ></v-text-field>

            <v-text-field
              type="number"
              v-model="form[`level${n}`]"
              v-for="n in 10"
              :key="n"
              :label="`Số lượng câu level ${n}`"
              :rules="[v => !isNaN(v) || 'Bạn chưa nhập trường này']"
            ></v-text-field>

            <v-btn @click="generateExam" class="d-block mx-auto" color="green"
              >Sinh đề</v-btn
            >
          </v-form></v-card-text
        >
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {
  getSubjects,
  generateExam,
  getExaminations,
  index as getOriginalExams
} from "@/api/original-exam";

export default {
  data() {
    return {
      subjects: [],
      baseSubjects: [],
      examinations: [],
      originalExams: [],
      valid: true,
      form: {
        examinationId: "",
        subjectId: "",
        baseSubjectId: "",
        originalExamIds: [],
        quantity: "",
        level1: 0,
        level2: 0,
        level3: 0,
        level4: 0,
        level5: 0,
        level6: 0,
        level7: 0,
        level8: 0,
        level9: 0,
        level10: 0
      }
    };
  },
  computed: {
    originalExamsFiltered() {
      if (!this.form.examinationId || !this.form.baseSubjectId) {
        return [];
      }

      const examination = this.examinations.find(
        item => item.id == this.form.examinationId
      );

      return this.originalExams.filter(
        item =>
          item.subjectId == this.form.baseSubjectId &&
          item.type == examination.type &&
          item.termId == examination.termId
      );
    }
  },
  methods: {
    resetOriginalExams() {
      if (this.form.originalExamIds.length > 0) this.form.originalExamIds = [];
    },
    async getBaseSubjects() {
      const { data } = await getSubjects();
      this.baseSubjects = data;
    },
    async getSubjects(val) {
      const examination = this.examinations.find(item => item.id === val);
      this.subjects = examination.subjects;
    },
    async getExaminations() {
      const { data } = await getExaminations();
      this.examinations = data;
    },
    async getOriginalExams() {
      const { data } = await getOriginalExams();
      this.originalExams = data;
    },
    async generateExam() {
      await this.$refs.form.validate();
      if (!this.valid) return;
      this.$loader(true);

      try {
        await generateExam({
          ...this.form,
          quantity: +this.form.quantity,
          level1: +this.form.level1,
          level2: +this.form.level2,
          level3: +this.form.level3,
          level4: +this.form.level4,
          level5: +this.form.level5,
          level6: +this.form.level6,
          level7: +this.form.level7,
          level8: +this.form.level8,
          level9: +this.form.level9,
          level10: +this.form.level10
        });

        this.$snackbar("Sinh đề thành công", "success");
        location.reload();
      } catch (error) {
        console.log(error);
      } finally {
        this.$loader(false);
      }
    }
  },
  created() {
    this.getExaminations();
    this.getBaseSubjects();
    this.getOriginalExams();
  }
};
</script>
