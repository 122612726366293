<template>
  <v-row>
    <v-col cols="3">
      <v-select
        :items="examinations"
        v-model="query.examinationId"
        item-text="name"
        item-value="id"
        label="Kíp thi"
        dense
        hide-details
      ></v-select>
    </v-col>
    <v-col cols="3">
      <v-select
        :items="subjects"
        item-text="fullName"
        v-model="query.subjectId"
        item-value="id"
        label="Học phần"
        dense
        hide-details
      ></v-select>
    </v-col>

    <v-col cols="3">
      <v-btn
        @click="getOriginalExams"
        :disabled="!query.subjectId || !query.examinationId"
        text
        >Xem</v-btn
      >
    </v-col>

    <v-col v-if="originalExam" cols="6">
      <v-card class="mt-0 pt-1" style="width:100%">
        <v-select
          :items="originalExams"
          v-model="selectedOriginalExam"
          label="Đề gốc"
          class="ml-4"
          hide-details
          item-text="name"
          style="width:50%"
          item-value="id"
        ></v-select>
        <v-card-text style="height: calc(100vh - 177px);overflow-y: auto">
          <div v-for="question in originalExam.questions" :key="question.id">
            <h4 class="mb-2">
              Câu {{ question.order }} - Level {{ question.level }}
            </h4>
            <img :src="question.content" alt="" />
            <div v-for="choice in question.choices" :key="choice.id">
              <p
                :style="{
                  color: choice.isCorrect ? 'green' : 'unset',
                  fontSize: '14px'
                }"
                class="my-2"
              >
                Phương án {{ choice.order }}:
                <span v-if="choice.type === 'text'">{{ choice.content }}</span>
              </p>
              <img :src="choice.image" v-if="choice.image" alt="" />
            </div>
            <v-divider class="my-2"></v-divider>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-if="exam" cols="6">
      <v-card class="mt-0 pt-1" style="width:100%">
        <v-select
          :items="exams"
          item-text="code"
          item-value="id"
          hide-details
          v-model="selectedExam"
          class="ml-4"
          style="width:50%"
          label="Mã đề"
        ></v-select>
        <v-card-text style="height: calc(100vh - 177px);overflow-y: auto">
          <div v-for="question in exam.questions" :key="question.id">
            <h4 class="mb-2">
              Câu {{ question.order }} - Level
              {{ question.originalQuestion.level }}
            </h4>
            <img :src="question.originalQuestion.content" alt="" />
            <div v-for="choice in question.choices" :key="choice.id">
              <p
                :style="{
                  color: choice.originalChoice.isCorrect ? 'green' : 'unset',
                  fontSize: '14px'
                }"
                class="my-2"
              >
                Phương án {{ choice.order }}:
                <span v-if="choice.originalChoice.type === 'text'">{{
                  choice.originalChoice.content
                }}</span>
              </p>
              <img
                :src="choice.originalChoice.image"
                v-if="choice.originalChoice.image"
                alt=""
              />
            </div>
            <v-divider class="my-2"></v-divider>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {
  getSubjects,
  index,
  getExaminations,
  getExams
} from "@/api/original-exam";
export default {
  data() {
    return {
      query: {
        subjectId: "",
        examinationId: ""
      },
      subjects: [],
      examinations: [],
      originalExams: [],
      exams: [],
      selectedOriginalExam: null,
      selectedExam: null
    };
  },
  computed: {
    originalExam() {
      return this.originalExams.find(i => i.id === this.selectedOriginalExam);
    },
    exam() {
      return this.exams.find(i => i.id === this.selectedExam);
    }
  },
  methods: {
    async getExams() {
      const { data } = await getExams({
        subjectId: this.query.subjectId,
        examinationId: this.query.examinationId
      });
      this.exams = data;
      this.selectedExam = data[0].id;
    },
    async getSubjects() {
      const { data } = await getSubjects();
      this.subjects = data;
    },
    async getExaminations() {
      const { data } = await getExaminations();
      this.examinations = data;
    },
    async getOriginalExams() {
      this.$loader(true);
      const examination = this.examinations.find(
        i => i.id === this.query.examinationId
      );
      const { data } = await index({
        subjectId: this.query.subjectId,
        termId: examination.termId,
        type: examination.type,
        withDetail: true
      });

      this.originalExams = data;
      this.selectedOriginalExam = data[0].id;
      this.getExams();
      this.$loader(false);
    }
  },
  created() {
    this.getSubjects();
    this.getExaminations();
  }
};
</script>