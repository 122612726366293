<template>
  <v-container style="height: calc(100vh - 60px); overflow-y: auto" fluid>
    <!-- <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab> Sinh đề</v-tab>
          <v-tab> Xem lại đề</v-tab>
        </v-tabs>
      </v-col>
    </v-row> -->
    <CreateExams v-if="tab === 0" />
    <PreviewExams v-else />
  </v-container>
</template>
<script>
import CreateExams from "./components/CreateExams.vue";
import PreviewExams from "./components/PreviewExams.vue";
export default {
  components: { CreateExams, PreviewExams },
  data() {
    return {
      tab: 0
    };
  }
};
</script>